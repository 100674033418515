body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Cabin";
  src: url("./assets/fonts/Cabin-Variable.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Sofia";
  src: url("./assets/fonts/Sofia 400.ttf") format("truetype");
}

.region path {
  fill: rgba(255, 255, 255, 0.5);
  transition: stroke-width 1s, fill 1s;
}

.selected-region path {
  fill: rgba(233, 82, 37, 0.6);
  position: relative;
  stroke: #e95225;
  stroke-width: 1;
  stroke-linejoin: "round";
}

.svg-map {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 10px;
}

.svg-map-desktop {
  padding-top: 80px;
  padding-left: 130px;
}

.svg-map * {
  transform: scale(0.7, 0.7);
}

.svg-map-desktop * path {
  transform: scale(0.7, 0.7);
}

.region-wrap {
  object-fit: contain;
  width: 100%;
  height: 70%;
  background-image: url("assets/images/regional-map-page.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.tooltip-text-desktop {
  font-family: "Sofia";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0.15px;
  fill: #ffffff;
  position: absolute;
  display: block;
  transform: none;
}

.tooltip-text {
  font-family: "Sofia";
  fill: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 30px;
  letter-spacing: 0.15px;
  position: absolute;
  display: block;
  transform: none;
}

.tooltip {
  display: none;
  animation: hideshow 10s 3s ease infinite;
}

g:hover > .tooltip {
  display: block;
  animation: hideshow 1s 0.1s ease;
}

.mapboxgl-popup-content-wrapper {
  padding: 1%;
}

.mapboxgl-popup-content {
  width: 178px;
  font-family: "Cabin";
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  padding: 0;
}

.mapboxgl-popup-content:hover {
  cursor: pointer;
}

.mapboxgl-popup-content .content {
  padding: 10px 10px 15px;
}

.mapboxgl-popup-content .title {
  font-family: "Poppins";
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.mapboxgl-popup-content .info {
  margin-top: 12px;
  font-family: "Cabin";
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #487c71;
}

.mapCluster {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  width: 36px;
  height: 36px;
  padding: 4px;
  cursor: pointer;
  opacity: 0.9;
  background: #e95225;
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-weight: 700;
}

.mapCluster:hover {
  cursor: pointer;
}
